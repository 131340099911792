import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="feats-metamagic"></a><h2>Feats</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Metamagic</span>
    </h2>
    <h5>EMPOWER SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: All variable, numeric effects
of an empowered spell are increased by one-half. Saving throws and
opposed rolls are not affected, nor are spells without random
variables. An empowered spell uses up a spell slot two levels higher
than the spell&#8217;s actual level.</p>
    <h5>ENLARGE SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: You can alter a spell with a
range of close, medium, or long to increase its range by 100%. An
enlarged spell with a range of close now has a range of 50 ft. + 5
ft./level, while medium-range spells have a range of 200 ft. + 20
ft./level and long-range spells have a range of 800 ft. + 80 ft./level.
An enlarged spell uses up a spell slot one level higher than the
spell&#8217;s actual level.</p>
    <p>Spells whose ranges are not defined by distance, as well as
spells whose ranges are not close, medium, or long, do not have
increased ranges.</p>
    <h5>EXTEND SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: An extended spell lasts twice
as long as normal. A spell with a duration of concentration,
instantaneous, or permanent is not affected by this feat. An extended
spell uses up a spell slot one level higher than the spell&#8217;s actual
level.</p>
    <h5>HEIGHTEN SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: A heightened spell has a
higher spell level than normal (up to a maximum of 9th level). Unlike
other metamagic feats, Heighten Spell actually increases the effective
level of the spell that it modifies. All effects dependent on spell
level (such as saving throw DCs and ability to penetrate a lesser globe
of invulnerability) are calculated according to the heightened level.
The heightened spell is as difficult to prepare and cast as a spell of
its effective level.</p>
    <h5>MAXIMIZE SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: All variable, numeric effects
of a spell modified by this feat are maximized. Saving throws and
opposed rolls are not affected, nor are spells without random
variables. A maximized spell uses up a spell slot three levels higher
than the spell&#8217;s actual level.</p>
    <p>An empowered, maximized spell gains the separate benefits of
each feat: the maximum result plus one-half the normally rolled result.</p>
    <h5>QUICKEN SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: Casting a quickened spell is a
free action. You can perform another action, even casting another
spell, in the same round as you cast a quickened spell. You may cast
only one quickened spell per round. A spell whose casting time is more
than 1 full round action cannot be quickened. A quickened spell uses up
a spell slot four levels higher than the spell&#8217;s actual level. Casting
a quickened spell doesn&#8217;t provoke an attack of opportunity.</p>
    <p><b>Special</b>: This feat can&#8217;t be applied to any spell cast
spontaneously (including sorcerer spells, bard spells, and cleric or
druid spells cast spontaneously), since applying a metamagic feat to a
spontaneously cast spell automatically increases the casting time to a
full-round action.</p>
    <h5>SILENT SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: A silent spell can be cast
with no verbal components. Spells without verbal components are not
affected. A silent spell uses up a spell slot one level higher than the
spell&#8217;s actual level.</p>
    <p><b>Special</b>: Bard spells cannot be enhanced by this
metamagic feat.</p>
    <h5>STILL SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: A stilled spell can be cast
with no somatic components.</p>
    <p>Spells without somatic components are not affected. A stilled
spell uses up a spell slot one level higher than the spell&#8217;s actual
level.</p>
    <h5>WIDEN SPELL [METAMAGIC]</h5>
    <p className="initial"><b>Benefit</b>: You can alter a burst,
emanation, line, or spread shaped spell to increase its area. Any
numeric measurements of the spell&#8217;s area increase by 100%. A widened
spell uses up a spell slot three levels higher than the spell&#8217;s actual
level.</p>
    <p>Spells that do not have an area of one of these four sorts are
not affected by this feat.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      